import React from "react";
import { BASE_IMG_URL } from "../../Helper/url";

export default function Feature() {
  return (
    <>
      <section className="featured_service_sec bg_color">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="heading text-center"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2>Featured Service</h2>
                <p>
                  Tailored solutions for diverse needs, delivering service
                  excellence, innovation, and commitment for your success and
                  satisfaction
                </p>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {/* Service Card 1 */}
            <div className="col-lg-6">
              <div
                className="sevice_card"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className="top_img">
                  <img
                    src={BASE_IMG_URL + "service-img1.webp"}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="overlay">
                    <ul className="service_ul">
                      <li>
                        <i className="ri-arrow-right-double-line"></i>Website
                        Design
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>Web
                        Application Development
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>
                        WordPress Development
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>Node JS
                        Development
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>PHP
                        Development
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>Angular
                        Development
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btom">
                  <h4>Website Development</h4>
                  <p>
                    Crafting responsive, user-centric websites, marrying design
                    and functionality to elevate online presence, user
                    experience, and business goals effectively.
                  </p>
                </div>
              </div>
            </div>

            {/* Service Card 2 */}
            <div className="col-lg-6">
              <div
                className="sevice_card"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className="top_img">
                  <img
                    src={BASE_IMG_URL + "service-img2.webp"}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="overlay">
                    <ul className="service_ul">
                      <li>
                        <i className="ri-arrow-right-double-line"></i>Mobile App
                        Development
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>Android
                        App Development
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>iOS App
                        Development
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>React
                        Native Development
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btom">
                  <h4>App Development</h4>
                  <p>
                    Creating intuitive, innovative mobile applications, merging
                    cutting-edge technology with user-centric design for
                    seamless experiences and impactful solutions.
                  </p>
                </div>
              </div>
            </div>

            {/* Service Card 3 */}
            <div className="col-lg-6">
              <div
                className="sevice_card"
                data-aos="fade-up"
                data-aos-duration="2500"
              >
                <div className="top_img">
                  <img
                    src={BASE_IMG_URL + "service-img3.webp"}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="overlay">
                    <ul className="service_ul">
                      <li>
                        <i className="ri-arrow-right-double-line"></i>Digital
                        marketing Services
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>SEO
                        Services
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>Social
                        Media Marketing
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>PPC
                        Management
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btom">
                  <h4> Digital Marketing</h4>
                  <p>
                    Strategizing online campaigns, utilizing SEO, social media,
                    and content to boost brand visibility, engagement, and
                    conversions, maximizing digital presence and growth.
                  </p>
                </div>
              </div>
            </div>

            {/* Service Card 4 */}
            <div className="col-lg-6">
              <div
                className="sevice_card"
                data-aos="fade-up"
                data-aos-duration="2500"
              >
                <div className="top_img">
                  <img
                     src={BASE_IMG_URL + "service-img4.webp"}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="overlay">
                    <ul className="service_ul">
                      <li>
                        <i className="ri-arrow-right-double-line"></i>
                        eCommerce Website
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>
                        eCommerce App
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>Shopify
                        Development
                      </li>
                      <li>
                        <i className="ri-arrow-right-double-line"></i>
                        WordPress eCommerce
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btom">
                  <h4>Ecommerce Development</h4>
                  <p>
                    Specializing in creating dynamic, secure online stores,
                    optimizing user experience and functionality for seamless
                    transactions and scalable business growth.
                  </p>
                </div>
              </div>
            </div>

            {/* Service Card 5 */}
            <div className="col-lg-12">
              <div
                className="sevice_card"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="row g-0">
                  <div className="col-sm-5 col-12">
                    <div className="content_img">
                      <img
                          src={BASE_IMG_URL + "service-img5.webp"}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-sm-7 col-12">
                    <div className="content_left">
                      <h4>Design & Branding</h4>
                      <p>
                        Crafting captivating visual identities and messaging
                        that resonate, strengthening brand recognition, trust,
                        and differentiation, driving memorable and impactful
                        audience connections.
                      </p>
                      <ul className="service_ul">
                        <li>
                          <i className="ri-arrow-right-double-line"></i>Logo
                          Design
                        </li>
                        <li>
                          <i className="ri-arrow-right-double-line"></i>
                          Corporate Identity
                        </li>
                        <li>
                          <i className="ri-arrow-right-double-line"></i>
                          Brochure Design
                        </li>
                        <li>
                          <i className="ri-arrow-right-double-line"></i>
                          Explainer Video
                        </li>
                        <li>
                          <i className="ri-arrow-right-double-line"></i>
                          Content Writing
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
